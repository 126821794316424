import { css } from '@emotion/react';
import { Box, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ComponentProps, FC } from 'react';

import { theme } from '@/Theme';

type DisplayItemProps = {
  itemList: { label: string; value?: string | null }[];
  gridProps?: Partial<ComponentProps<typeof Grid>>;
  gridLabelProps?: Partial<ComponentProps<typeof Grid>>;
  gridValueProps?: Partial<ComponentProps<typeof Grid>>;
};

export const applicantsItemStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderBottom: `solid 1px ${grey[200]}`,
  padding: '8px',
  minHeight: '40px',
  wordBreak: 'break-all',
});

export const DisplayItem: FC<DisplayItemProps> = ({ itemList, gridProps, gridLabelProps, gridValueProps }) => {
  return (
    <Box borderTop={`solid 1px ${grey[200]}`}>
      {itemList.map(item => {
        return (
          <Grid item xs={12} key={item.label} container {...gridProps}>
            <Grid container>
              <Grid
                item
                css={[applicantsItemStyle, { backgroundColor: theme.palette.secondary.light }]}
                xs={12}
                md={4}
                {...gridLabelProps}
              >
                <Typography fontWeight="bold">{item.label}</Typography>
              </Grid>
              <Grid item xs={12} md={8} css={applicantsItemStyle} {...gridValueProps}>
                {item.value}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

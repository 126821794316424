import { Denwa } from '@/Models/Esa/EsaCommon';
import { EsaPublicDomain, LogoImage } from '@/Models/Esa/EsaDomain';
import { EsaPublicOrganization, Jusho } from '@/Models/Esa/EsaOrganization';
import { PartnerStatusCollection, PartnerStatusCollectionType } from '@/Models/Esa/Partner';
import { PartnerApplicants } from '@/Models/Esa/PartnerApplicants';
import noimageSrc from '@/noimage.svg';

export const getDenwaNumber = (denwaList: Denwa[]): string => {
  return (
    denwaList.find(
      denwa =>
        denwa.denwaNumberShubetsu === '電話' ||
        denwa.denwaNumberShubetsu === '携帯' ||
        denwa.denwaNumberShubetsu === 'スマートフォン'
    )?.denwaNumber ?? '-'
  );
};

export const getFaxNumber = (denwaList: Denwa[]): string => {
  return denwaList.find(denwa => denwa.denwaNumberShubetsu === 'FAX')?.denwaNumber ?? '-';
};

export const getLogoImageUrl = (logoImage: LogoImage | null): string => {
  const url = logoImage?.url;
  if (!url) {
    return noimageSrc;
  }
  return url;
};

export const getJusho = (jusho: Jusho | null): string => {
  if (!jusho || !jusho.jushoText) {
    return '-';
  }
  if (jusho.nokoriJusho) {
    if (jusho.gaikuGoto) {
      return jusho.jushoText + jusho.nokoriJusho + jusho.gaikuGoto;
    }
    return jusho.jushoText + jusho.nokoriJusho;
  }
  return jusho.jushoText;
};

/**
 * 組織のステータスを判定する
 * @param publicOrganizationDomainUid 組織の
 * @param partnerOrganizationUidList デフォルト組織の取引先 UID リスト
 * @param partnerApplicantsSentStandbyUidList デフォルト組織の申請中の取引先申請 UID リスト
 * @param partnerApplicantsReceivedStandbyUidList デフォルト組織のの取引先申請 UID リスト
 * @returns
 */
export const getPartnerStatus = (
  publicOrganizationDomainUid: string,
  partnerOrganizationUidList: string[],
  partnerApplicantsSentStandbyUidList: string[],
  partnerApplicantsReceivedStandbyUidList: string[]
): PartnerStatusCollectionType => {
  if (partnerOrganizationUidList.includes(publicOrganizationDomainUid)) {
    return PartnerStatusCollection.partner;
  } else if (partnerApplicantsSentStandbyUidList.includes(publicOrganizationDomainUid)) {
    return PartnerStatusCollection.sentStandby;
  } else if (partnerApplicantsReceivedStandbyUidList.includes(publicOrganizationDomainUid)) {
    return PartnerStatusCollection.receivedStandby;
  } else {
    return PartnerStatusCollection.notPartner;
  }
};

export const getPartnerStatusChip = (
  partnerStatus?: PartnerStatusCollectionType
):
  | {
      label: string;
      color: string;
    }
  | undefined => {
  switch (partnerStatus) {
    case PartnerStatusCollection.partner:
      return { label: '取引先', color: '#48A550' };
    case PartnerStatusCollection.sentStandby:
      return { label: '申請中', color: '#FC8F2A' };
    case PartnerStatusCollection.receivedStandby:
      return { label: '承認待', color: '#FC8F2A' };
    default:
      return;
  }
};

/**
 * 組織情報に法人情報や取引先申請の情報を付け加える
 * @param publicOrganizationList 組織一覧
 * @param publicDomainList 法人一覧
 * @param sentPartnerApplicants 送信した申請一覧
 * @param receivedPartnerApplicants 受信した申請一覧
 * @returns 法人情報や取引先申請の情報が加わった組織一覧
 */
export const attachInfoToPublicOrganizationList = (
  publicOrganizationList: EsaPublicOrganization[],
  publicDomainList: EsaPublicDomain[],
  sentPartnerApplicants: PartnerApplicants[],
  receivedPartnerApplicants: PartnerApplicants[]
): (EsaPublicOrganization & {
  domain: EsaPublicDomain;
  sentApplicants: PartnerApplicants;
  receivedApplicants: PartnerApplicants;
})[] => {
  return publicOrganizationList.map((organization: EsaPublicOrganization) => {
    const publicDomainListTemp = publicDomainList.filter(
      (d: EsaPublicDomain) => d.domainUid === organization.domainUid
    )[0];
    const sentPartnerApplicantsTemp = sentPartnerApplicants.filter((a: PartnerApplicants) => {
      return a.partnerOrganizationUid === organization.organizationUid;
    })[0];
    const receivedPartnerApplicantsTemp = receivedPartnerApplicants.filter(
      (a: PartnerApplicants) => a.organizationUid === organization.organizationUid
    )[0];
    return {
      ...organization,
      domain: publicDomainListTemp,
      sentApplicants: sentPartnerApplicantsTemp,
      receivedApplicants: receivedPartnerApplicantsTemp,
    };
  });
};

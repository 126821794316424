import { Button, ButtonGroup } from '@e-seikatsu/design-system';
import { css } from '@emotion/react';
import { useIsExtraSmallDevice } from '@lib/components';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Close } from 'mdi-material-ui';
import { FC, memo, useCallback, useMemo, useState } from 'react';

import { applicantsItemStyle } from '@/Components/Forms/DisplayItem';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { useEsaDomainOrganizations, useUpdateEsaOrganization } from '@/Hooks/Esa/useEsaDomainOrganizations';
import { useEnqueueSnackbar } from '@/Hooks/Snackbar';
import { EsaOrganization, PartnerSearchPublicStatusCollection } from '@/Models/Esa/EsaOrganization';
import { theme } from '@/Theme';

type PartnerSearchPublicEditDialogContainerProps = {
  isOpenDialog: boolean;
  closeDialog: () => void;
  settingOrganization: EsaOrganization;
};

const PartnerSearchPublicEditDialogContainer: FC<PartnerSearchPublicEditDialogContainerProps> = memo(
  ({ isOpenDialog, closeDialog, settingOrganization }) => {
    const isExtraSmallDevice = useIsExtraSmallDevice();
    const enqueueSnackbar = useEnqueueSnackbar();
    const user = useCurrentUser();
    const hasMasterRole = useMemo(() => {
      return user.role.isAdmin;
    }, [user.role.isAdmin]);

    const [partnerSearchPublicStatus, setPartnerSearchPublicStatus] = useState<boolean>(settingOrganization.isPublic);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { updateEsaOrganization } = useUpdateEsaOrganization();
    const { mutate } = useEsaDomainOrganizations({
      domainUid: settingOrganization.domainUid,
    });
    const saveSettings = useCallback(async () => {
      setIsLoading(true);
      if (hasMasterRole) {
        await updateEsaOrganization(settingOrganization.organizationUid, { isPublic: partnerSearchPublicStatus })
          .then(() =>
            enqueueSnackbar('公開設定を保存しました', {
              variant: 'success',
            })
          )
          .catch(() =>
            enqueueSnackbar('公開設定の保存に失敗しました。時間をおいて再度お試しください。', {
              variant: 'error',
            })
          );
      } else {
        enqueueSnackbar('公開設定の保存に失敗しました。管理者権限がないため、管理者にお問い合わせください。', {
          variant: 'error',
        });
      }
      setIsLoading(false);
      closeDialog();
      await mutate();
    }, [
      hasMasterRole,
      closeDialog,
      mutate,
      updateEsaOrganization,
      settingOrganization.organizationUid,
      partnerSearchPublicStatus,
      enqueueSnackbar,
    ]);

    const buttonGroup = useMemo(
      () => [
        {
          label: PartnerSearchPublicStatusCollection.private,
          value: false,
        },
        {
          label: PartnerSearchPublicStatusCollection.public,
          value: true,
        },
      ],
      []
    );

    const handleTogglePartnerSearchPublicStatus = useCallback((selectedItemValue: boolean | null) => {
      if (selectedItemValue === null) {
        setPartnerSearchPublicStatus(prevStatus => prevStatus);
      } else {
        setPartnerSearchPublicStatus(selectedItemValue);
      }
    }, []);

    return (
      <PartnerSearchPublicEditDialogPresenter
        isExtraSmallDevice={isExtraSmallDevice}
        isOpenDialog={isOpenDialog}
        closeDialog={closeDialog}
        partnerSearchPublicStatus={partnerSearchPublicStatus}
        handleTogglePartnerSearchPublicStatus={handleTogglePartnerSearchPublicStatus}
        isLoading={isLoading}
        saveSettings={saveSettings}
        buttonGroup={buttonGroup}
      />
    );
  }
);

export { PartnerSearchPublicEditDialogContainer as PartnerSearchPublicEditDialog };

type PartnerSearchPublicEditDialogPresenterProps = {
  isExtraSmallDevice: boolean;
  isOpenDialog: boolean;
  closeDialog: () => void;
  partnerSearchPublicStatus: boolean;
  handleTogglePartnerSearchPublicStatus: (selectedItemValue: boolean | null) => void;
  isLoading: boolean;
  saveSettings: () => Promise<void>;
  buttonGroup: {
    label: string;
    value: boolean;
  }[];
};

const dialogTitleStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '50px',
});

const dialogActionStyle = css({
  textAlign: 'right',
});

export const PartnerSearchPublicEditDialogPresenter: FC<PartnerSearchPublicEditDialogPresenterProps> = memo(
  ({
    isExtraSmallDevice,
    isOpenDialog,
    closeDialog,
    buttonGroup,
    partnerSearchPublicStatus,
    handleTogglePartnerSearchPublicStatus,
    isLoading,
    saveSettings,
  }) => (
    <Dialog open={isOpenDialog} maxWidth="sm" fullWidth fullScreen={isExtraSmallDevice}>
      <DialogTitle css={dialogTitleStyle}>
        <Box display="flex" alignItems="center" fontWeight="bold">
          組織情報公開
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton onClick={closeDialog} size="large">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography mb={2}>
          非公開にすると、他ユーザーが取引先検索するときにあなたの組織情報が表示されなくなり、新規の申請が来なくなります。承認済みのユーザーは取引先に残ります。
        </Typography>
        <Grid item xs={12} container borderTop={`solid 1px ${grey[200]}`}>
          <Grid container>
            <Grid item css={[applicantsItemStyle, { backgroundColor: theme.palette.secondary.light }]} xs={12} md={4}>
              <Typography>公開状態</Typography>
            </Grid>
            <Grid item xs={12} md={8} css={applicantsItemStyle}>
              <ButtonGroup
                defaultSelected={partnerSearchPublicStatus}
                buttons={buttonGroup}
                onClick={handleTogglePartnerSearchPublicStatus}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions css={dialogActionStyle}>
        <Button variant="text" onClick={closeDialog}>
          キャンセル
        </Button>
        <Button onClick={saveSettings} isLoading={isLoading}>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  )
);

import { Alert } from '@e-seikatsu/design-system';
import { css } from '@emotion/react';
import { useIsExtraSmallDevice } from '@lib/components';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { ArrowLeft, Close } from 'mdi-material-ui';
import { ChangeEvent, FC, memo, useCallback, useMemo, useState } from 'react';
import { mutate } from 'swr';

import { PartnerApplicantsStepper } from './PartnerApplicantsStepper';

import { DisplayItem, applicantsItemStyle } from '@/Components/Forms/DisplayItem';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { useEsaDomain } from '@/Hooks/Esa/useEsaDomain';
import { OrganizationMap, useEsaDomainOrganizations } from '@/Hooks/Esa/useEsaDomainOrganizations';
import { usePostPartnerApplicants } from '@/Hooks/Esa/usePartnerApplicants';
import { EsaPublicDomain } from '@/Models/Esa/EsaDomain';
import { EsaPublicOrganization } from '@/Models/Esa/EsaOrganization';
import { PartnerApplicantsPostPayload } from '@/Models/Esa/PartnerApplicants';
import { theme } from '@/Theme';
import { makeTakkenLicenseNumber } from '@/Utils/DisplayText/Takken';
import { getKameiDantai } from '@/Utils/EsaUtils';
import { getPartnerApplicantDomainOrganizationName } from '@/Utils/PartnerApplicantsUtils';
import { getJusho, getDenwaNumber } from '@/Utils/PartnerUtils';

type PartnerApplicantsSendDialogContainerProps = {
  isOpenDialog: boolean;
  closeDialog: () => void;
  domain: EsaPublicDomain;
  organization: EsaPublicOrganization;
};

const initialApplicantsComment = 'はじめまして。\nぜひ貴社とお取引させていただきたく、承認お願い申し上げます。';
const MAX_APPLICANTS_COMMENT = 300;
const PartnerApplicantsSendDialogContainer: FC<PartnerApplicantsSendDialogContainerProps> = memo(
  ({ isOpenDialog, closeDialog, domain, organization }) => {
    const isExtraSmallDevice = useIsExtraSmallDevice();
    const userInfo = useCurrentUser();
    const { domain: userDomain } = useEsaDomain(userInfo.domainUid);
    const { organizations } = useEsaDomainOrganizations({
      domainUid: userInfo.domainUid,
    });
    const organizationMap: OrganizationMap = new Map(
      organizations?.map(organization => [organization.organizationUid, organization])
    );
    const userDefaultOrganization = organizationMap.get(userInfo.defaultOrganizationUid);

    const applicantsSource: { label: string; value?: string }[] = useMemo(() => {
      return [
        ...getPartnerApplicantDomainOrganizationName(domain, organization),
        {
          label: '住所',
          value: getJusho(organization.jusho),
        },
        {
          label: '電話',
          value: getDenwaNumber(organization.denwaList),
        },
        {
          label: '免許番号',
          value: makeTakkenLicenseNumber(
            domain.takkenLicense?.licensor ?? '',
            domain.takkenLicense?.updateCount ?? '',
            domain.takkenLicense?.number ?? ''
          ),
        },
        {
          label: '加盟団体',
          value: getKameiDantai(organization.kameiDantaiList),
        },
      ];
    }, [domain, organization]);

    const applicantsDestination: { label: string; value?: string | null }[] = useMemo(() => {
      return [
        {
          label: '会社名',
          value: userDomain?.name,
        },
        {
          label: '組織名（店舗名）',
          value: userDefaultOrganization?.name,
        },
        {
          label: '申込送付者',
          value: userInfo.name,
        },
      ];
    }, [userDefaultOrganization, userDomain, userInfo.name]);

    const { postPartnerApplicants } = usePostPartnerApplicants();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [applicantsComment, setApplicantsComment] = useState(initialApplicantsComment);
    const isExceedsCharacterLimit = applicantsComment.length > MAX_APPLICANTS_COMMENT;
    const applicantsRequestBody: PartnerApplicantsPostPayload = useMemo(() => {
      return {
        approvalComment: '',
        cancellationComment: '',
        comment: applicantsComment,
        organizationUid: userDefaultOrganization?.organizationUid ?? '',
        partnerOrganizationUid: organization.organizationUid,
        rejectionComment: '',
      };
    }, [applicantsComment, userDefaultOrganization?.organizationUid, organization.organizationUid]);

    const handlePostPartnerApplicatns = useCallback(async () => {
      setIsLoading(true);
      await postPartnerApplicants(applicantsRequestBody);
      setIsLoading(false);
      closeDialog();
      // GraphQL を考慮すると、key に string 以外の型も入るので型チェックを行う
      await mutate(key => typeof key === 'string' && key.startsWith('/partner_applicants'));
    }, [applicantsRequestBody, closeDialog, postPartnerApplicants]);

    const handleApplicantsComment = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setApplicantsComment(e.target.value);
    }, []);

    return (
      <PartnerApplicantsSendDialogPresenter
        isExtraSmallDevice={isExtraSmallDevice}
        isOpenDialog={isOpenDialog}
        closeDialog={closeDialog}
        applicantsSource={applicantsSource}
        applicantsDestination={applicantsDestination}
        isLoading={isLoading}
        handlePostPartnerApplicatns={handlePostPartnerApplicatns}
        applicantsComment={applicantsComment}
        handleApplicantsComment={handleApplicantsComment}
        isExceedsCharacterLimit={isExceedsCharacterLimit}
      />
    );
  }
);

export { PartnerApplicantsSendDialogContainer as PartnerApplicantsSendDialog };

type PartnerApplicantsSendDialogPresenterProps = {
  isExtraSmallDevice: boolean;
  isOpenDialog: boolean;
  closeDialog: () => void;
  applicantsSource: {
    label: string;
    value?: string;
  }[];
  applicantsDestination: {
    label: string;
    value?: string | null | undefined;
  }[];
  isLoading: boolean;
  handlePostPartnerApplicatns: () => void;
  applicantsComment: string;
  handleApplicantsComment: (e: ChangeEvent<HTMLInputElement>) => void;
  isExceedsCharacterLimit: boolean;
};

const dialogTitleStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '50px',
});

const dialogActionStyle = css({
  justifyContent: 'space-between',
});

const previousStepButtonStyle = css({
  marginLeft: '16px',
});

const commentLengthStyle = css({ color: theme.palette.text.secondary });
const commentLengthAlertStyle = css({ color: theme.palette.error.main });

export const PartnerApplicantsSendDialogPresenter: FC<PartnerApplicantsSendDialogPresenterProps> = memo(
  ({
    isExtraSmallDevice,
    isOpenDialog,
    closeDialog,
    applicantsSource,
    applicantsDestination,
    isLoading,
    handlePostPartnerApplicatns,
    applicantsComment,
    handleApplicantsComment,
    isExceedsCharacterLimit,
  }) => (
    <Dialog open={isOpenDialog} maxWidth="md" fullWidth fullScreen={isExtraSmallDevice}>
      <DialogTitle css={dialogTitleStyle}>
        <Box display="flex" alignItems="center" fontWeight="bold">
          取引先申請の送付
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton onClick={closeDialog} size="large">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <PartnerApplicantsStepper activeStepNumber={0} />
        <Box pt={3}>
          <Alert color="warning">
            <Alert.Content>
              <Typography fontSize="13px">
                ・貴社情報をもとに承認審査をおこないますので、不備がないか必ずご確認ください。
              </Typography>
              <Typography fontSize="13px">
                ・複数組織に所属している方は申請の送信元をお間違えないようご注意ください。
              </Typography>
            </Alert.Content>
          </Alert>
        </Box>
        <Box fontWeight="bold" fontSize="18px" borderLeft="8px solid #52A0D6" pl={1} mt={2} mb={1}>
          申請コメント
        </Box>
        <Grid item xs={12} container>
          <Grid container>
            <Grid item css={[applicantsItemStyle, { backgroundColor: theme.palette.secondary.light }]} xs={12} md={4}>
              <Typography fontWeight="bold">コメント</Typography>
            </Grid>
            <Grid item xs={12} md={8} css={applicantsItemStyle}>
              <TextField
                multiline
                minRows={2}
                fullWidth
                value={applicantsComment}
                onChange={handleApplicantsComment}
                inputRef={input => input && !isExtraSmallDevice && input.focus()}
                onFocus={e =>
                  e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
                }
                error={isExceedsCharacterLimit}
                helperText={isExceedsCharacterLimit && '300文字以内で入力してください'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box pt={1} ml={1} css={isExceedsCharacterLimit ? commentLengthAlertStyle : commentLengthStyle}>
          {applicantsComment.length}/{MAX_APPLICANTS_COMMENT}
        </Box>
        <Box fontWeight="bold" fontSize="18px" borderLeft="8px solid #52A0D6" pl={1} mt={2} mb={1}>
          申請先
        </Box>
        <DisplayItem itemList={applicantsSource} />
        <Box fontWeight="bold" fontSize="18px" borderLeft="8px solid #52A0D6" pl={1} mt={2} mb={1}>
          申請元
        </Box>
        <DisplayItem itemList={applicantsDestination} />
      </DialogContent>
      <Divider />
      <DialogActions css={dialogActionStyle}>
        <Button onClick={closeDialog} variant="outlined" startIcon={<ArrowLeft />} css={previousStepButtonStyle}>
          不動産会社組織一覧へ戻る
        </Button>
        <LoadingButton
          variant="contained"
          css={{ marginRight: '8px' }}
          onClick={handlePostPartnerApplicatns}
          loading={isLoading}
          disabled={isExceedsCharacterLimit}
        >
          取引先申請する
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
);

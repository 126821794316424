import { Button } from '@e-seikatsu/design-system';
import { Box, Tooltip } from '@mui/material';
import { FC, memo, ReactNode } from 'react';

import { EsaOrganization } from '@/Models/Esa/EsaOrganization';
import { theme } from '@/Theme';

type SettingCommonDisplayProps = {
  openDialog: () => void;
  dataGuide: string;
  isDisableEditButton: boolean;
  toolTipMessage: string;
  settingOrganization: EsaOrganization;
  settingTitle: string;
  settingContent: ReactNode;
};

export const SettingCommonDisplay: FC<SettingCommonDisplayProps> = memo(
  ({ openDialog, dataGuide, isDisableEditButton, toolTipMessage, settingTitle, settingContent }) => (
    <Box p={2} m={2} css={{ background: theme.palette.background.paper }} data-guide={dataGuide}>
      <Box display="flex" alignItems="center" mb={1}>
        <Box fontWeight="bold" fontSize="18px" borderLeft="8px solid #52A0D6" pl={1} mr={1}>
          {settingTitle}
        </Box>
        <Tooltip title={toolTipMessage} placement="top" disableInteractive>
          <span>
            <Button
              variant="outlined"
              size="small"
              onClick={openDialog}
              prependIcon="edit"
              disabled={isDisableEditButton}
            >
              編集
            </Button>
          </span>
        </Tooltip>
      </Box>
      {settingContent}
    </Box>
  )
);

import { isNotNullOrUndefined } from '@app/utils';
import { css } from '@emotion/react';
import { IconButtonMenu, textSxProps, useIsExtraSmallDevice, useOnOffState } from '@lib/components';
import { Box, Chip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ChannelMyOrganization, ChannelPartnerOrganization } from 'graphql-client/src/API';
import { DotsHorizontal } from 'mdi-material-ui';
import { FC, useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { PartnerApplicantsReceivedDialog } from './Applicants/PartnerApplicantsReceivedDialog';
import { PartnerApplicantsSendDialog } from './Applicants/PartnerApplicantsSendDialog';
import { PartnerApplicantsSentDialog } from './Applicants/PartnerApplicantsSentDialog';
import { PartnerDeleteDialog } from './PartnerDeleteDialog';
import { PartnerStatusButton } from './PartnerStatusButton';

import { analyticsPlaceNameBusinessPartnerManagement } from '@/Consts/Values';
import { useUserActionTracker } from '@/Hooks/Analytics';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { EsaPublicDomain } from '@/Models/Esa/EsaDomain';
import { EsaOrganization, EsaPublicOrganization } from '@/Models/Esa/EsaOrganization';
import { PartnerStatusCollection, PartnerStatusCollectionType } from '@/Models/Esa/Partner';
import { PartnerApplicants } from '@/Models/Esa/PartnerApplicants';
import { RouteDefinitions } from '@/Pages/RouteDefinitions';
import { getDenwaNumber, getJusho, getLogoImageUrl, getPartnerStatusChip } from '@/Utils/PartnerUtils';
import noimageSrc from '@/noimage.svg';

type PartnerOrganizationItemContainerProps = {
  domain: EsaPublicDomain;
  organization: EsaPublicOrganization;
  sentApplicants?: PartnerApplicants;
  receivedApplicants?: PartnerApplicants;
  partnerStatus?: PartnerStatusCollectionType;
  channelUid?: string;
  channelMyOrganization?: ChannelMyOrganization | null;
  channelPartnerOrganization?: ChannelPartnerOrganization | null;
  isRead?: boolean | null;
  active?: boolean | null;
  userOrganizations?: EsaOrganization[];
  partnerUid?: string;
};

const PartnerOrganizationItemContainer: FC<PartnerOrganizationItemContainerProps> = ({
  domain,
  organization,
  sentApplicants,
  receivedApplicants,
  partnerStatus,
  channelUid,
  channelMyOrganization,
  channelPartnerOrganization,
  isRead,
  active,
  userOrganizations,
  partnerUid,
}) => {
  const navigate = useNavigate();
  const isExtraSmallDevice = useIsExtraSmallDevice();
  const user = useCurrentUser();
  const hasMasterRole = useMemo(() => {
    return user.role.isAdmin;
  }, [user.role.isAdmin]);

  const [isOpenApplicantsSendDialog, { setTrue: openApplicantsSendDialog, setFalse: closeApplicantsSendDialog }] =
    useOnOffState(false);
  const [isOpenApplicantsSentDialog, { setTrue: openApplicantsSentDialog, setFalse: closeApplicantsSentDialog }] =
    useOnOffState(false);
  const [
    isOpenApplicantsReceivedDialog,
    { setTrue: openApplicantsReceivedDialog, setFalse: closeApplicantsReceivedDialog },
  ] = useOnOffState(false);
  const [isOpenDeleteDialog, { setTrue: openDeleteDialog, setFalse: closeDeleteDialog }] = useOnOffState(false);

  const tracker = useUserActionTracker(analyticsPlaceNameBusinessPartnerManagement);
  const onOpenDialog = () => {
    tracker('取引先申請ボタン押下');
    openApplicantsSendDialog();
  };

  const partnerDomainName = useMemo(() => {
    if (active === false) {
      return channelPartnerOrganization?.domainName;
    }

    return domain?.name;
  }, [active, channelPartnerOrganization?.domainName, domain]);

  const partnerOrganizationName = useMemo(() => {
    if (active === false) {
      return channelPartnerOrganization?.organizationName;
    }

    return organization?.name;
  }, [active, channelPartnerOrganization, organization]);

  const partnerOrganizationBusinessName = useMemo(() => {
    if (active === false) {
      return channelPartnerOrganization?.organizationBusinessName;
    }

    return organization?.businessName;
  }, [active, channelPartnerOrganization?.organizationBusinessName, organization?.businessName]);

  const channelMyOrganizationName = useMemo(() => {
    return (
      userOrganizations?.find(o => {
        return o?.organizationUid === channelMyOrganization?.organizationUid;
      })?.name ?? channelMyOrganization?.organizationName
    );
  }, [channelMyOrganization?.organizationName, channelMyOrganization?.organizationUid, userOrganizations]);

  const isMultipleOrganization = useMemo(() => {
    return userOrganizations ? userOrganizations.length > 1 : false;
  }, [userOrganizations]);

  // NOTE: partnerStatus が undefind の場合は取引先一覧、それ以外は組織検索になっている
  const isInactiveChannel = useMemo(() => partnerStatus === undefined && !active, [active, partnerStatus]);

  const denwaNumber = useMemo(() => {
    if (!organization) {
      return '';
    }
    return getDenwaNumber(organization.denwaList);
  }, [organization]);

  const organizationJusho = useMemo(() => {
    if (!organization || isInactiveChannel) {
      return '';
    }
    return getJusho(organization.jusho);
  }, [isInactiveChannel, organization]);

  const imageUrl = useMemo(() => {
    if (!domain || !domain.logoImage) {
      return noimageSrc;
    }
    return getLogoImageUrl(domain.logoImage);
  }, [domain]);

  const partnerStatusChip = getPartnerStatusChip(partnerStatus);

  return isExtraSmallDevice ? (
    <PartnerOrganizationItemMobilePresenter
      domain={domain}
      organization={organization}
      sentApplicants={sentApplicants}
      receivedApplicants={receivedApplicants}
      denwaNumber={denwaNumber}
      organizationJusho={organizationJusho}
      imageUrl={imageUrl}
      partnerStatusChip={partnerStatusChip}
      navigate={navigate}
      partnerStatus={partnerStatus}
      isOpenApplicantsSendDialog={isOpenApplicantsSendDialog}
      openApplicantsSendDialog={onOpenDialog}
      closeApplicantsSendDialog={closeApplicantsSendDialog}
      isOpenApplicantsSentDialog={isOpenApplicantsSentDialog}
      openApplicantsSentDialog={openApplicantsSentDialog}
      closeApplicantsSentDialog={closeApplicantsSentDialog}
      isOpenApplicantsReceivedDialog={isOpenApplicantsReceivedDialog}
      openApplicantsReceivedDialog={openApplicantsReceivedDialog}
      closeApplicantsReceivedDialog={closeApplicantsReceivedDialog}
      isOpenDeleteDialog={isOpenDeleteDialog}
      openDeleteDialog={openDeleteDialog}
      closeDeleteDialog={closeDeleteDialog}
      channelUid={channelUid}
      isRead={isRead}
      active={active}
      partnerDomainName={partnerDomainName ?? ''}
      partnerOrganizationName={partnerOrganizationName ?? ''}
      partnerOrganizationBusinessName={partnerOrganizationBusinessName}
      channelMyOrganizationName={channelMyOrganizationName}
      isMultipleOrganization={isMultipleOrganization}
      partnerUid={partnerUid}
      hasMasterRole={hasMasterRole}
      isInactiveChannel={isInactiveChannel}
    />
  ) : (
    <PartnerOrganizationItemPresenter
      domain={domain}
      organization={organization}
      sentApplicants={sentApplicants}
      receivedApplicants={receivedApplicants}
      denwaNumber={denwaNumber}
      organizationJusho={organizationJusho}
      imageUrl={imageUrl}
      partnerStatusChip={partnerStatusChip}
      navigate={navigate}
      partnerStatus={partnerStatus}
      isOpenApplicantsSendDialog={isOpenApplicantsSendDialog}
      openApplicantsSendDialog={onOpenDialog}
      closeApplicantsSendDialog={closeApplicantsSendDialog}
      isOpenApplicantsSentDialog={isOpenApplicantsSentDialog}
      openApplicantsSentDialog={openApplicantsSentDialog}
      closeApplicantsSentDialog={closeApplicantsSentDialog}
      isOpenApplicantsReceivedDialog={isOpenApplicantsReceivedDialog}
      openApplicantsReceivedDialog={openApplicantsReceivedDialog}
      closeApplicantsReceivedDialog={closeApplicantsReceivedDialog}
      isOpenDeleteDialog={isOpenDeleteDialog}
      openDeleteDialog={openDeleteDialog}
      closeDeleteDialog={closeDeleteDialog}
      channelUid={channelUid}
      isRead={isRead}
      active={active}
      partnerDomainName={partnerDomainName ?? ''}
      partnerOrganizationName={partnerOrganizationName ?? ''}
      partnerOrganizationBusinessName={partnerOrganizationBusinessName}
      channelMyOrganizationName={channelMyOrganizationName}
      isMultipleOrganization={isMultipleOrganization}
      partnerUid={partnerUid}
      hasMasterRole={hasMasterRole}
      isInactiveChannel={isInactiveChannel}
    />
  );
};

export { PartnerOrganizationItemContainer as PartnerOrganizationItem };

type PartnerOrganizationItemPresenterProps = {
  domain: EsaPublicDomain;
  organization: EsaPublicOrganization;
  sentApplicants?: PartnerApplicants;
  receivedApplicants?: PartnerApplicants;
  denwaNumber: string;
  organizationJusho: string;
  imageUrl: string;
  partnerStatusChip?: {
    label: string;
    color: string;
  };
  navigate: NavigateFunction;
  partnerStatus?: PartnerStatusCollectionType;
  isOpenApplicantsSendDialog: boolean;
  openApplicantsSendDialog: () => void;
  closeApplicantsSendDialog: () => void;
  isOpenApplicantsSentDialog: boolean;
  openApplicantsSentDialog: () => void;
  closeApplicantsSentDialog: () => void;
  isOpenApplicantsReceivedDialog: boolean;
  openApplicantsReceivedDialog: () => void;
  closeApplicantsReceivedDialog: () => void;
  isOpenDeleteDialog: boolean;
  openDeleteDialog: () => void;
  closeDeleteDialog: () => void;
  channelUid?: string;
  isRead?: boolean | null;
  active?: boolean | null;
  partnerDomainName: string;
  partnerOrganizationName: string;
  partnerOrganizationBusinessName: string | null | undefined;
  channelMyOrganizationName?: string | null;
  isMultipleOrganization: boolean;
  partnerUid?: string;
  hasMasterRole: boolean;
  isInactiveChannel: boolean;
};

const toBukkenSearchButtonStyle = css({
  '&:hover': {
    cursor: 'pointer',
  },
  textDecoration: 'underline',
});

const tagStyle = (backGroundcolor?: string) =>
  css({
    borderRadius: '2px',
    height: '20px',
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'white',
    background: backGroundcolor,
  });

export const PartnerOrganizationItemPresenter: FC<PartnerOrganizationItemPresenterProps> = ({
  domain,
  organization,
  sentApplicants,
  receivedApplicants,
  denwaNumber,
  organizationJusho,
  imageUrl,
  partnerStatusChip,
  navigate,
  partnerStatus,
  isOpenApplicantsSendDialog,
  openApplicantsSendDialog,
  closeApplicantsSendDialog,
  isOpenApplicantsSentDialog,
  openApplicantsSentDialog,
  closeApplicantsSentDialog,
  isOpenApplicantsReceivedDialog,
  openApplicantsReceivedDialog,
  closeApplicantsReceivedDialog,
  isOpenDeleteDialog,
  openDeleteDialog,
  closeDeleteDialog,
  channelUid,
  isRead,
  active,
  partnerDomainName,
  partnerOrganizationName,
  partnerOrganizationBusinessName,
  channelMyOrganizationName,
  isMultipleOrganization,
  partnerUid,
  hasMasterRole,
  isInactiveChannel,
}) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns={isMultipleOrganization ? '1.3fr 3.6fr 2fr 1.3fr 1fr 1fr' : '1.3fr 3.6fr 2fr 1.3fr 1.3fr'}
      gridAutoRows="100%"
      alignItems="center"
      bgcolor={active === false ? grey[300] : theme => theme.palette.background.paper}
      pl={1}
      py={1}
      mb={2}
      mx={2}
      minHeight="88px"
      borderRadius="4px"
    >
      <img
        alt={domain?.logoImage?.fileUid ?? ''}
        src={imageUrl}
        style={{ objectFit: 'contain', width: '100%', height: '88px', paddingRight: '8px', paddingLeft: '8px' }}
      />
      <Box mx={1}>
        {isInactiveChannel && <Chip size="small" label="過去の取引先" css={tagStyle(grey[500])} />}
        {partnerStatus !== undefined && partnerStatus !== PartnerStatusCollection.notPartner && (
          <Chip size="small" label={partnerStatusChip?.label} css={tagStyle(partnerStatusChip?.color)} />
        )}
        <Box mt={0.5} mb={1} fontSize="16px" fontWeight="bold" css={{ wordBreak: 'break-all' }}>
          {partnerOrganizationBusinessName ? (
            <>{partnerOrganizationBusinessName}</>
          ) : (
            <>
              {partnerDomainName} {partnerOrganizationName}
            </>
          )}
        </Box>
        <Box sx={{ wordBreak: 'break-all' }}>{organizationJusho}</Box>
      </Box>
      {isInactiveChannel ? (
        <Box />
      ) : (
        <Box mx={1} sx={{ wordBreak: 'break-all' }}>
          <Box>TEL：{denwaNumber}</Box>
        </Box>
      )}
      {isInactiveChannel ? (
        <Box />
      ) : (
        <Box mx={1}>
          <Box display="flex" alignItems="center">
            <Box mr={0.5}>取引先</Box>
            <Box sx={{ ...textSxProps.bold }}>{organization?.partnerCount ?? 0}</Box>
          </Box>
          <Box
            onClick={() =>
              navigate({
                pathname: RouteDefinitions.chintaiBukkenSearch.path,
                search: `motozuke_gyosha_name=${domain?.name} ${organization?.name}`,
              })
            }
            width="90px"
            css={toBukkenSearchButtonStyle}
          >
            公開物件一覧
          </Box>
        </Box>
      )}
      {isMultipleOrganization && (
        <Box mx={1} css={{ overflowWrap: 'anywhere' }}>
          {channelMyOrganizationName}
        </Box>
      )}
      <Box display="flex" alignItems="center" textAlign="right" mr={1}>
        <PartnerStatusButton
          partnerStatus={partnerStatus}
          channelUid={channelUid}
          isRead={isRead}
          openApplicantsSendDialog={openApplicantsSendDialog}
          openApplicantsSentDialog={openApplicantsSentDialog}
          openApplicantsReceivedDialog={openApplicantsReceivedDialog}
        />
        {partnerStatus === undefined ? (
          <IconButtonMenu
            icon={<DotsHorizontal />}
            menuActions={[
              {
                text: '取引先から削除',
                onSelected: openDeleteDialog,
                disabled: !hasMasterRole || !active,
                disabledText: active ? '管理者のみ削除できます' : '',
              },
            ]}
          />
        ) : (
          <div />
        )}
      </Box>
      {isOpenApplicantsSendDialog && (
        <PartnerApplicantsSendDialog
          isOpenDialog={isOpenApplicantsSendDialog}
          closeDialog={closeApplicantsSendDialog}
          domain={domain}
          organization={organization}
        />
      )}
      {isNotNullOrUndefined(sentApplicants) && isOpenApplicantsSentDialog && (
        <PartnerApplicantsSentDialog
          isOpenViaDialog={isOpenApplicantsSentDialog}
          closeViaDialog={closeApplicantsSentDialog}
          viaPartnerApplicant={sentApplicants}
        />
      )}
      {isNotNullOrUndefined(receivedApplicants) && isOpenApplicantsReceivedDialog && (
        <PartnerApplicantsReceivedDialog
          isOpenViaDialog={isOpenApplicantsReceivedDialog}
          closeViaDialog={closeApplicantsReceivedDialog}
          viaPartnerApplicant={receivedApplicants}
        />
      )}
      {isOpenDeleteDialog && (
        <PartnerDeleteDialog
          isOpenDeleteDialog={isOpenDeleteDialog}
          closeDeleteDialog={closeDeleteDialog}
          partnerUid={partnerUid}
        />
      )}
    </Box>
  );
};

export const PartnerOrganizationItemMobilePresenter: FC<PartnerOrganizationItemPresenterProps> = ({
  domain,
  organization,
  sentApplicants,
  receivedApplicants,
  denwaNumber,
  organizationJusho,
  imageUrl,
  partnerStatusChip,
  navigate,
  partnerStatus,
  isOpenApplicantsSendDialog,
  openApplicantsSendDialog,
  closeApplicantsSendDialog,
  isOpenApplicantsSentDialog,
  openApplicantsSentDialog,
  closeApplicantsSentDialog,
  isOpenApplicantsReceivedDialog,
  openApplicantsReceivedDialog,
  closeApplicantsReceivedDialog,
  isOpenDeleteDialog,
  openDeleteDialog,
  closeDeleteDialog,
  channelUid,
  isRead,
  active,
  partnerDomainName,
  partnerOrganizationName,
  partnerOrganizationBusinessName,
  channelMyOrganizationName,
  isMultipleOrganization,
  partnerUid,
  hasMasterRole,
  isInactiveChannel,
}) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 2.2fr"
      gridAutoRows="100%"
      bgcolor={active === false ? grey[300] : theme => theme.palette.background.paper}
      py={1}
      pl={1}
      my={2}
      borderRadius="4px"
    >
      <img
        alt={domain?.logoImage?.fileUid ?? ''}
        src={imageUrl}
        style={{ objectFit: 'contain', width: '100%', maxHeight: '100px', marginTop: '12px' }}
      />
      <Box ml={2} pr={2}>
        <Box my={1}>
          {isInactiveChannel && <Chip size="small" label="過去の取引先" css={tagStyle(grey[500])} />}
          {partnerStatus !== undefined && partnerStatus !== PartnerStatusCollection.notPartner && (
            <Chip size="small" label={partnerStatusChip?.label} css={tagStyle(partnerStatusChip?.color)} />
          )}
          <Box mt={0.5} mb={1} fontSize="16px" fontWeight="bold" css={{ wordBreak: 'break-all' }}>
            {partnerOrganizationBusinessName ? (
              <>{partnerOrganizationBusinessName}</>
            ) : (
              <>
                {partnerDomainName} {partnerOrganizationName}
              </>
            )}
          </Box>
          <Box sx={{ wordBreak: 'break-all' }}>{organizationJusho}</Box>
        </Box>
        {isInactiveChannel ? (
          <Box />
        ) : (
          <Box my={1} sx={{ wordBreak: 'break-all' }}>
            <Box>TEL：{denwaNumber}</Box>
          </Box>
        )}
        {isInactiveChannel ? (
          <Box />
        ) : (
          <Box display="flex">
            <Box>取引先</Box>
            <Box mx={0.5} sx={{ ...textSxProps.bold }}>
              {organization?.partnerCount}
            </Box>
            <Box
              onClick={() =>
                navigate({
                  pathname: RouteDefinitions.chintaiBukkenSearch.path,
                  search: `motozuke_gyosha_name=${domain?.name} ${organization?.name}`,
                })
              }
              width="90px"
              css={toBukkenSearchButtonStyle}
            >
              公開物件一覧
            </Box>
          </Box>
        )}
        {isMultipleOrganization && (
          <Box mt={1} css={{ overflowWrap: 'anywhere' }}>
            担当： {channelMyOrganizationName}
          </Box>
        )}
        <Box display="flex" alignItems="center" justifyContent="flex-end" mr={1} my={1}>
          <PartnerStatusButton
            partnerStatus={partnerStatus}
            channelUid={channelUid}
            isRead={isRead}
            openApplicantsSendDialog={openApplicantsSendDialog}
            openApplicantsSentDialog={openApplicantsSentDialog}
            openApplicantsReceivedDialog={openApplicantsReceivedDialog}
          />
          {partnerStatus === undefined && (
            <IconButtonMenu
              icon={<DotsHorizontal />}
              menuActions={[
                {
                  text: '取引先から削除',
                  onSelected: openDeleteDialog,
                  disabled: !hasMasterRole || !active,
                  disabledText: active ? '管理者のみ削除できます' : '',
                },
              ]}
              padding="0"
            />
          )}
        </Box>
        {isOpenApplicantsSendDialog && (
          <PartnerApplicantsSendDialog
            isOpenDialog={isOpenApplicantsSendDialog}
            closeDialog={closeApplicantsSendDialog}
            domain={domain}
            organization={organization}
          />
        )}
        {isNotNullOrUndefined(sentApplicants) && isOpenApplicantsSentDialog && (
          <PartnerApplicantsSentDialog
            isOpenViaDialog={isOpenApplicantsSentDialog}
            closeViaDialog={closeApplicantsSentDialog}
            viaPartnerApplicant={sentApplicants}
          />
        )}
        {isNotNullOrUndefined(receivedApplicants) && isOpenApplicantsReceivedDialog && (
          <PartnerApplicantsReceivedDialog
            isOpenViaDialog={isOpenApplicantsReceivedDialog}
            closeViaDialog={closeApplicantsReceivedDialog}
            viaPartnerApplicant={receivedApplicants}
          />
        )}
        {isOpenDeleteDialog && (
          <PartnerDeleteDialog
            isOpenDeleteDialog={isOpenDeleteDialog}
            closeDeleteDialog={closeDeleteDialog}
            partnerUid={partnerUid}
          />
        )}
      </Box>
    </Box>
  );
};

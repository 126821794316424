import { useOnOffState } from '@lib/components';
import { Box, Link } from '@mui/material';
import { OpenInNew } from 'mdi-material-ui';
import { FC, Fragment, memo, useMemo } from 'react';

import { PartnerAutoApprovalEditDialog } from './PartnerAutoApprovalEditDialog';
import { SettingCommonDisplay } from './SettingCommonDisplay';

import { DisplayItem } from '@/Components/Forms/DisplayItem';
import { supportSiteUrl } from '@/Consts/ExternalSiteUrls';
import { notAdminSettingMessage } from '@/Consts/Messages';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import {
  EsaOrganization,
  PartnerAutoApprovalStatusCollection,
  PartnerAutoApprovalStatusCollectionType,
} from '@/Models/Esa/EsaOrganization';

type PartnerAutoApprovalSettingContainerProps = {
  settingOrganization: EsaOrganization;
};

const PartnerAutoApprovalSettingContainer: FC<PartnerAutoApprovalSettingContainerProps> = memo(
  ({ settingOrganization }) => {
    const user = useCurrentUser();
    const hasMasterRole = useMemo(() => {
      return user.role.isAdmin;
    }, [user.role.isAdmin]);

    const [
      isOpenPartnerAutoApprovalSettingDialog,
      { setTrue: openPartnerAutoApprovalSettingDialog, setFalse: closePartnerAutoApprovalSettingDialog },
    ] = useOnOffState(false);

    const partnerAutoApprovalStatus: PartnerAutoApprovalStatusCollectionType = useMemo(() => {
      return settingOrganization.partnerAutoApproval.enabled
        ? PartnerAutoApprovalStatusCollection.partnerAutoApproval
        : PartnerAutoApprovalStatusCollection.partnerManualApproval;
    }, [settingOrganization.partnerAutoApproval]);

    const toolTipMessage = useMemo(() => {
      return hasMasterRole ? '' : notAdminSettingMessage;
    }, [hasMasterRole]);

    return (
      <PartnerAutoApprovalSettingPresenter
        isOpenDialog={isOpenPartnerAutoApprovalSettingDialog}
        openDialog={openPartnerAutoApprovalSettingDialog}
        closeDialog={closePartnerAutoApprovalSettingDialog}
        hasMasterRole={hasMasterRole}
        toolTipMessage={toolTipMessage}
        settingOrganization={settingOrganization}
        partnerAutoApprovalStatus={partnerAutoApprovalStatus}
      />
    );
  }
);

export { PartnerAutoApprovalSettingContainer as PartnerAutoApprovalSetting };

type PartnerAutoApprovalSettingPresenterProps = {
  isOpenDialog: boolean;
  openDialog: () => void;
  closeDialog: () => void;
  hasMasterRole: boolean;
  toolTipMessage: string;
  settingOrganization: EsaOrganization;
  partnerAutoApprovalStatus: PartnerAutoApprovalStatusCollectionType;
};

export const PartnerAutoApprovalSettingPresenter: FC<PartnerAutoApprovalSettingPresenterProps> = memo(
  ({
    isOpenDialog,
    openDialog,
    closeDialog,
    hasMasterRole,
    toolTipMessage,
    settingOrganization,
    partnerAutoApprovalStatus,
  }) => (
    <Fragment>
      <SettingCommonDisplay
        openDialog={openDialog}
        dataGuide="organization-auto-approval-setting"
        isDisableEditButton={!hasMasterRole}
        toolTipMessage={toolTipMessage}
        settingOrganization={settingOrganization}
        settingTitle="取引先申請の審査設定"
        settingContent={
          <>
            <Box my={1}>
              取引先申請を受信した時の審査方法を管理します。
              <br />
              取引先申請については
              <Link
                href={`${supportSiteUrl}/knowledge-base/メッセージ機能・取引先管理とは何ですか？/#lwptoc2`}
                target="_blank"
                rel="noopener noreferrer"
                display="inline"
              >
                サポートサイト
                <OpenInNew css={{ verticalAlign: 'middle', fontSize: '16px' }} />
              </Link>
              をご確認ください。
            </Box>
            <DisplayItem
              itemList={[{ label: '審査方法', value: partnerAutoApprovalStatus }]}
              gridLabelProps={{ height: '20px' }}
              gridValueProps={{ height: '20px' }}
            />
          </>
        }
      />
      {isOpenDialog && (
        <PartnerAutoApprovalEditDialog
          isOpenDialog={isOpenDialog}
          closeDialog={closeDialog}
          settingOrganization={settingOrganization}
        />
      )}
    </Fragment>
  )
);

import { Button, ButtonGroup } from '@e-seikatsu/design-system';
import { css } from '@emotion/react';
import { useIsExtraSmallDevice } from '@lib/components';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Close } from 'mdi-material-ui';
import { FC, memo, useCallback, useMemo, useState } from 'react';

import { applicantsItemStyle } from '@/Components/Forms/DisplayItem';
import { useCurrentUser } from '@/Hooks/Esa/RequireCurrentUser';
import { useEsaDomainOrganizations, useUpdateEsaOrganization } from '@/Hooks/Esa/useEsaDomainOrganizations';
import { useEnqueueSnackbar } from '@/Hooks/Snackbar';
import { EsaOrganization, PartnerAutoApprovalStatusCollection } from '@/Models/Esa/EsaOrganization';
import { theme } from '@/Theme';

type PartnerAutoApprovalEditDialogContainerProps = {
  isOpenDialog: boolean;
  closeDialog: () => void;
  settingOrganization: EsaOrganization;
};

const PartnerAutoApprovalEditDialogContainer: FC<PartnerAutoApprovalEditDialogContainerProps> = memo(
  ({ isOpenDialog, closeDialog, settingOrganization }) => {
    const isExtraSmallDevice = useIsExtraSmallDevice();
    const enqueueSnackbar = useEnqueueSnackbar();
    const user = useCurrentUser();
    const hasMasterRole = useMemo(() => {
      return user.role.isAdmin;
    }, [user.role.isAdmin]);

    const [isPartnerAutoApproval, setIsPartnerAutoApproval] = useState<boolean>(
      settingOrganization.partnerAutoApproval.enabled
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { updateEsaOrganization } = useUpdateEsaOrganization();
    const { mutate } = useEsaDomainOrganizations({
      domainUid: settingOrganization.domainUid,
    });
    const saveSettings = useCallback(async () => {
      setIsLoading(true);
      if (hasMasterRole) {
        await updateEsaOrganization(settingOrganization.organizationUid, {
          partnerAutoApproval: { ...settingOrganization.partnerAutoApproval, enabled: isPartnerAutoApproval },
        })
          .then(() =>
            enqueueSnackbar('審査設定を保存しました', {
              variant: 'success',
            })
          )
          .catch(() =>
            enqueueSnackbar('審査設定の保存に失敗しました。時間をおいて再度お試しください。', {
              variant: 'error',
            })
          );
      } else {
        enqueueSnackbar('審査設定の保存に失敗しました。管理者権限がないため、管理者にお問い合わせください。', {
          variant: 'error',
        });
      }
      setIsLoading(false);
      closeDialog();
      await mutate();
    }, [
      hasMasterRole,
      closeDialog,
      mutate,
      updateEsaOrganization,
      settingOrganization.organizationUid,
      settingOrganization.partnerAutoApproval,
      isPartnerAutoApproval,
      enqueueSnackbar,
    ]);

    const buttonGroup = useMemo(
      () => [
        {
          label: PartnerAutoApprovalStatusCollection.partnerAutoApproval,
          value: true,
        },
        {
          label: PartnerAutoApprovalStatusCollection.partnerManualApproval,
          value: false,
        },
      ],
      []
    );

    const handleTogglePartnerAutoApproval = useCallback((selectedItemValue: boolean | null) => {
      if (selectedItemValue === null) {
        setIsPartnerAutoApproval(prevStatus => prevStatus);
      } else {
        setIsPartnerAutoApproval(selectedItemValue);
      }
    }, []);

    return (
      <PartnerAutoApprovalEditDialogPresenter
        isExtraSmallDevice={isExtraSmallDevice}
        isOpenDialog={isOpenDialog}
        closeDialog={closeDialog}
        isPartnerAutoApproval={isPartnerAutoApproval}
        handleTogglePartnerAutoApproval={handleTogglePartnerAutoApproval}
        isLoading={isLoading}
        saveSettings={saveSettings}
        buttonGroup={buttonGroup}
      />
    );
  }
);

export { PartnerAutoApprovalEditDialogContainer as PartnerAutoApprovalEditDialog };

type PartnerAutoApprovalEditDialogPresenterProps = {
  isExtraSmallDevice: boolean;
  isOpenDialog: boolean;
  closeDialog: () => void;
  isPartnerAutoApproval: boolean;
  handleTogglePartnerAutoApproval: (selectedItemValue: boolean | null) => void;
  isLoading: boolean;
  saveSettings: () => Promise<void>;
  buttonGroup: {
    label: string;
    value: boolean;
  }[];
};

const dialogTitleStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '50px',
});

const dialogActionStyle = css({
  textAlign: 'right',
});

export const PartnerAutoApprovalEditDialogPresenter: FC<PartnerAutoApprovalEditDialogPresenterProps> = memo(
  ({
    isExtraSmallDevice,
    isOpenDialog,
    closeDialog,
    isPartnerAutoApproval,
    handleTogglePartnerAutoApproval,
    isLoading,
    saveSettings,
    buttonGroup,
  }) => (
    <Dialog open={isOpenDialog} maxWidth="sm" fullWidth fullScreen={isExtraSmallDevice}>
      <DialogTitle css={dialogTitleStyle}>
        <Box display="flex" alignItems="center" fontWeight="bold">
          取引先申請の審査設定
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton onClick={closeDialog} size="large">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography mb={2}>
          自動承認…受信した申請を自動ですべて承認し取引先関係になります。
          <br />
          手動承認…申請を受信する度に管理者が承認/却下の審査を行います。
        </Typography>
        <Grid item xs={12} container borderTop={`solid 1px ${grey[200]}`}>
          <Grid container>
            <Grid item css={[applicantsItemStyle, { backgroundColor: theme.palette.secondary.light }]} xs={12} md={4}>
              <Typography>審査方法</Typography>
            </Grid>
            <Grid item xs={12} md={8} css={applicantsItemStyle}>
              <ButtonGroup
                defaultSelected={isPartnerAutoApproval}
                buttons={buttonGroup}
                onClick={handleTogglePartnerAutoApproval}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions css={dialogActionStyle}>
        <Button variant="text" onClick={closeDialog}>
          キャンセル
        </Button>
        <Button onClick={saveSettings} isLoading={isLoading}>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  )
);
